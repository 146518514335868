
$(document).ready(function () {
    var docPath = '';
    var captchaToken = '';
    var captchaContactToken = '';
    var isValidEmail = false;
    window.validateEmail = function (email) {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        isValidEmail = Array.isArray(email.match(emailRegex));
        return isValidEmail;
    }
    $(".team-items").on({
        mouseenter: function () {
            $(this).siblings(".default").removeClass('default');
        },
        mouseleave: function () {
            $(".team-items:first-child").addClass("default");
        }
    });
    $('.dropdown').hover(function () { $(this).toggleClass('open') });
    $('.v1-box').hover(function () { $(this).siblings(".v1-image").toggleClass('show') });
    $('.v2-box').hover(function () { $(this).siblings(".v2-image").toggleClass('show') });
    $('.v3-box').hover(function () { $(this).siblings(".v3-image").toggleClass('show') });
    $('.v4-box').hover(function () { $(this).siblings(".v4-image").toggleClass('show') });
    $('.one').hover(function () { $(this).siblings(".one").toggleClass('show') });
    $('.two').hover(function () { $(this).siblings(".two").toggleClass('show') });
    $('.three').hover(function () { $(this).siblings(".three").toggleClass('show') });
    $('.four').hover(function () { $(this).siblings(".four").toggleClass('show') });
    $('.five').hover(function () { $(this).siblings(".five").toggleClass('show') });
    $('.six').hover(function () { $(this).siblings(".six").toggleClass('show') });
    $('.seven').hover(function () { $(this).siblings(".seven").toggleClass('show') });
    $('.eight').hover(function () { $(this).siblings(".eight").toggleClass('show') });
    $('.nine').hover(function () { $(this).siblings(".nine").toggleClass('show') });
    $('#emailBtn').click(function () {
        var nameValue = document.getElementById("cName").value;
        var email = document.getElementById("cEmail").value;
        validateEmail(email)

        if (nameValue && email && isValidEmail) {
            Email.send({
                Host: "smtp.elasticemail.com",
                Port: 2525,
                Username: "uyasin@stellatechnology.com",
                Password: "8A1A2067F746F460045709F39B7DEE7C3FE5",
                To: "sales@stellatechnology.com",
                From: "uyasin@stellatechnology.com",
                Subject: "Stella Website - Contact Us",
                Body: nameValue + " contacted Stella technology from Stella Website. Please respond back on: " + email,
            })
                .then(function (message) {
                    $('#modelContent').html("<p>Thank you for contacting us, we will get back to you soon.</p>");
                    $('#modelFooter').hide();
                    $('.modal-title').hide();
                });
        } else if (!isValidEmail) {
            alert("Please enter valid email");
        } else {
            alert("Please fill in the details");
        }
    });
    $('#subcribeBtn').click(function () {
        var email = document.getElementById("subscribeEmail").value;
        if (email &&
            email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
            Email.send({
                Host: "smtp.elasticemail.com",
                Port: 2525,
                Username: "uyasin@stellatechnology.com",
                Password: "8A1A2067F746F460045709F39B7DEE7C3FE5",
                To: "sales@stellatechnology.com",
                From: "uyasin@stellatechnology.com",
                Subject: "Stella Website - Contact Us",
                Body: email + " downloaded a case study from Stella Website. " + $('.download-pdf').attr("href")
            });
        }
    });

    $('#solutionSubscribeBtn').click(function () {
        var email = document.getElementById("solutionEmail").value;
        if (email) {
            Email.send({
                Host: "smtp.elasticemail.com",
                Port: 2525,
                Username: "uyasin@stellatechnology.com",
                Password: "8A1A2067F746F460045709F39B7DEE7C3FE5",
                To: "sales@stellatechnology.com",
                From: "uyasin@stellatechnology.com",
                Subject: "Stella Website - Solutions Contact Us",
                Body: email + " is reaching out for Stella Equinox from Stella Website. "
            }).then(function (message) {
                $('#successTxt').html("<p>Thank you for reaching us. We will get back to you soon.</p>");
            });
        }
    });

    $(".case-studies-btn").click(function () {
        $('html, body').animate({
            scrollTop: $("#case-studies").offset().top - 60
        }, 300);
    });

    $("#cName").keyup(function (event) {
        if (event.keyCode === 13) {
            $("#emailBtn").click();
        }
    });
    $("#cEmail").keyup(function (event) {
        if (event.keyCode === 13) {
            $("#emailBtn").click();
        }
    });

    window.setPdfPath = function (path) {
        docPath = path;
    }

    const onCaptchacall = function (response) {
        captchaContactToken = response;
        if (response) {
            window.contactEmail(response);
        }
    }

    window.contactEmail = function (token) {
        captchaContactToken = token
        var nameValue = document.getElementById("cName").value;
        var email = document.getElementById("cEmail").value;
        var message = document.getElementById("cMessage").value;
        const isValidEmail = validateEmail(email);

        if (nameValue && isValidEmail && captchaContactToken) {
            Email.send({
                Host: "smtp.elasticemail.com",
                Port: 2525,
                Username: "uyasin@stellatechnology.com",
                Password: "8A1A2067F746F460045709F39B7DEE7C3FE5",
                To: "sales@stellatechnology.com",
                From: "uyasin@stellatechnology.com",
                Subject: "Stella Website - Contact Us",
                Body: `${nameValue} contacted Stella technology from Stella Website. Please respond back on: ${email}. ${message?.length ? `Message: ${message}` : ""}`,
            })
                .then(function (message) {
                    $('#modelContent').append("<p class='result-info'>Thank you for reaching us. We will get back to you soon.</p>");
                    $('#modelFooter').hide();
                    $('.modal-title').hide();
                    $('#cName').hide();
                    $('#cEmail').hide();
                    $('#cMessage').hide();
                    grecaptcha.reset();
                });
        } else if (!isValidEmail) {
            alert("Please enter valid email");
        } else {
            alert("Please fill in the details");
        }
    }


    window.emailSend = function (email) {
        validateEmail(email)
        if (email &&
            isValidEmail
        ) {
            Email.send({
                Host: "smtp.elasticemail.com",
                Port: 2525,
                Username: "uyasin@stellatechnology.com",
                Password: "8A1A2067F746F460045709F39B7DEE7C3FE5",
                To: "sales@stellatechnology.com",
                From: "uyasin@stellatechnology.com",
                Subject: "Stella Website - Contact Us",
                Body: email + " downloaded a case study from Stella Website. " + $('.download-pdf').attr("href")
            });
            grecaptcha.reset();
        }
    }



    window.setPdfPathToDownload = function () {
        const a = document.createElement('a')
        a.href = docPath
        if (docPath) {
            a.download = docPath.split('/').pop()
            document.body.appendChild(a)
            a.click()
            a.target = '_blank'
            window.open(a);
            document.body.removeChild(a)
            docPath = ''
        }
    }

    window.handleEmailChangeCheck = function (event, modalId) {
        if (captchaContactToken) {
            resetCaptcha(modalId);
        }
        $(`#${modalId} #resultDownload`).text('');
        $(`#${modalId} #resultContact`).text('');
        document.getElementById("resultDownload").textContent = "";
        document.getElementById("resultContact").textContent = "";
    }

    window.handleEmailChange = function (event, modalId) {
        if (captchaToken) {
            resetCaptcha(modalId);
        }

        // const email = event.target.value;
        // const isValidEmail = validateEmail(email)

        const $resultDownload = $(`#${modalId} #resultDownload`);
        const $resultContact = $(`#${modalId} #resultDownload`);
        $resultDownload.text('');
        $resultContact.text('');
        document.getElementById("resultDownload").textContent = "";
        document.getElementById("resultContact").textContent = "";

        // NOTE - commenting out for now since required to clear the helper text when user starts typing
        // if (isValidEmail) {
        //     $resultDownload.text('');
        //     $resultContact.text('');
        // }

        // else if (!email) {
        //     $resultDownload.text('');
        //     $resultContact.text('');
        // } else {
        //     $resultDownload.text('Please provide a valid email address.');
        //     $resultContact.text('Please provide a valid email address.');
        //     //$result.text(email + ' is an invalid email.');
        //     //$result.css('color', 'red');
        // }
    }

    const onCaptchaSuccess = function (response) {
        captchaToken = response;
        const email = document.getElementById("subscribeEmail").value;
        validateEmail(email);
        if (isValidEmail) {
            window.handleSubscribe(response);

        }
    }

    const resetCaptcha = function (modalId) {
        // Recreate the reCAPTCHA element
        const captchaContainer = modalId ? document.querySelector(`#${modalId} #subscribeCaptcha`) : document.getElementById(`#subscribeCaptcha`);
        const newCaptcha = document.createElement("div");
        newCaptcha.classList.add("g-recaptcha");
        newCaptcha.setAttribute("data-sitekey", "6LdGilEnAAAAALR-SnMZcH4Jvof8sSev3DZJ97Ts");
        modalId === 'contactModal' ? newCaptcha.setAttribute("data-callback", "onCaptchacall") : newCaptcha.setAttribute("data-callback", "onCaptchaSuccess");

        // Clear the captchaToken
        captchaToken = '';
        captchaContactToken = '';
        // Replace the existing CAPTCHA with the new one
        captchaContainer.innerHTML = '';
        captchaContainer.appendChild(newCaptcha);

        // Reset the reCAPTCHA
        grecaptcha.render(newCaptcha, {
            'sitekey': '6LdGilEnAAAAALR-SnMZcH4Jvof8sSev3DZJ97Ts'
        });
    }



    window.onload = function () {
        grecaptcha.ready(function () {
            grecaptcha.execute('6LdGilEnAAAAALR-SnMZcH4Jvof8sSev3DZJ97Ts', { action: 'submit' })
        });
    }



    window.handleSubscribe = function (token) {
        const email = document.getElementById("subscribeEmail").value;
        const isValidEmail = validateEmail(email);
        captchaToken = token
        if (isValidEmail) {
            onload();
        }
        if (captchaToken && email && isValidEmail) {
            emailSend(email);
            setPdfPathToDownload(email);
            $(".modal").modal("hide");
            document.getElementById("subscribeEmail").value = "";
            resetCaptcha('downloadModal');
        } else {
            return;
        }
    }

    window.handleModalSubmit = function (modalId, emailId, nameId) {
        const nameValue = $(`#${nameId}`).val();
        const email = $(`#${emailId}`).val();
        const $resultDownload = $(`#${modalId} #resultDownload`);
        const $resultContact = $(`#${modalId} #resultContact`);

        const isValidEmail = validateEmail(email);

        if (nameValue === '') {
            $resultDownload.text('Please provide full name.')
            $resultContact.text('Please provide full name.')
            return false;
        }

        if ((isValidEmail && nameValue !== '') || (isValidEmail && modalId === 'downloadModal')) {
            $resultDownload.text('');
            $resultContact.text('');
            $(`#${modalId} #subscribeCaptcha`).css('display', 'flex');
            $(`#${modalId} #subcribeBtn`).hide();
        } else {
            $resultDownload.text('Please provide a valid email address.')
            $resultContact.text('Please provide a valid email address.')
            $(`#${modalId} #subscribeCaptcha`).hide();
        }
    }

    var scrollTop = document.getElementById("scrollTop");

    window.onscroll = function () {
        scrollfunction()
    };
    function scrollfunction() {

        if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
            scrollTop.style.display = "flex";
        } else {
            scrollTop.style.display = "none";
        }
    }
    scrollTop.addEventListener("click", function () {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: "smooth"
        })
    })

    function resetModalContent(modal) {
        if (modal.attr("id") === 'contactModal') {
            modal.find('#modelContent .result-info').remove();
            modal.find('#modelFooter').show();
            modal.find('.modal-title').show();
            modal.find('#cName, #cEmail, #cMessage').show().val('');
            grecaptcha.reset();
        } else {
            modal.find('#subscribeEmail').val('');
            resetCaptcha(modal.attr("id"));
        }

        modal.find('#resultDownload').val('');
        modal.find('#resultContact').val('');
        modal.find('#subscribeCaptcha').hide();
        modal.find('#subcribeBtn').show();

        document.getElementById("resultDownload").textContent = "";
        document.getElementById("resultContact").textContent = "";
    }

    $("#contactModal, #downloadModal").on("hidden.bs.modal", function () {
        resetModalContent($(this));
    });

    $.get("https://ipinfo.io/json?token=c921640f15a6b2", function (response) {
        var countryCode = response.country;

        // To-Do: update to uae url
        var uaeUrl = "/uae-url";

        if (window.location.origin === (window.location.href).slice(0, -1) && countryCode === "AE") {
            window.location.href = uaeUrl;
        }
    });

    var typingTimer;
    var doneTypingInterval = 1000;
    var loadingSpinner = $('.loading-spinner');
    var searchIcon = $('.icon-wrapper');

    function filterBlogs() {
        loadingSpinner.hide();
        searchIcon.show();
        var filter = $('#searchbar-field').val().toLowerCase();
        $('.resource-item').each(function () {
            var blogTitle = $(this).find('h2').text().toLowerCase();
            if (blogTitle.includes(filter)) {
                $(this).show();
            } else {
                $(this).hide();
            }
        });
    }

    $('#searchbar-field').on('input', function () {
        loadingSpinner.show();
        searchIcon.hide();
        clearTimeout(typingTimer);
        typingTimer = setTimeout(filterBlogs, doneTypingInterval);
    });


    $(function () {
        $('#show1').click(function () {
            $("#content2, #content3, #content4, #content5,#content6, #image2, #image3, #image4, #image5,#image6").hide();
            $('#show2, #show3, #show4, #show5, #show6').removeClass('active');
            $("#slideBar2, #slideBar3, #slideBar4, #slideBar5,#slideBar6").removeClass("active");
            $("#show1").addClass("active");
            $('#content1, #image1').show();
            $(".slideBar").css("top", '0%');
            // $(this).addClass("active");
            return false;
        });
        $('#show2').click(function (e) {
            e.preventDefault();
            $('#content1, #content3, #content4, #content5,#content6, #image1, #image3, #image4, #image5, #image6').hide();
            $('#show1, #show3, #show4, #show5, #show6').removeClass('active');
            $("#slideBar1, #slideBar3, #slideBar4, #slideBar5, #slideBar6").removeClass("active");
            $("#show2").addClass("active");
            $('#content2, #image2').show();
            $(".slideBar").css("top", '16.6%');
            // $(this).addClass("active");
            return false;
        });
        $('#show3').click(function (e) {
            e.preventDefault();
            $('#content1, #content2, #content4, #content5, #content6, #image1, #image2, #image4, #image5, #image6').hide();
            $('#show1, #show2, #show4, #show5, #show6').removeClass('active');
            $("#slideBar1, #slideBar2, #slideBar3, #slideBar4, #slideBar5").removeClass("active");
            $("#show3").addClass("active");
            $('#content3, #image3').show();
            $(".slideBar").css("top", '33.2%');
            // $(this).addClass("active");
            return false;
        });
        $('#show4').click(function (e) {
            e.preventDefault();
            $('#content1, #content2, #content3, #content5, #content6, #image1, #image2, #image3, #image5, #image6').hide();
            $('#show1, #show2, #show3, #show5, #show6').removeClass('active');
            $("#slideBar1, #slideBar2, #slideBar3, #slideBar5, #slideBar6").removeClass("active");
            $("#slideBar4, #show4").addClass("active");
            $('#content4, #image4').show();
            $(".slideBar").css("top", '49.8%');
            // $(this).addClass("active");
            return false;
        });
        $('#show5').click(function (e) {
            e.preventDefault();
            $('#content1, #content2, #content3, #content4,#content6, #image1, #image2, #image3, #image4, #image6').hide();
            $('#show1, #show2, #show3, #show4, #show6').removeClass('active');
            $("#slideBar1, #slideBar2, #slideBar3, #slideBar4, #slideBar6").removeClass("active");
            $("#show5").addClass("active");
            $('#content5, #image5').show();
            $(".slideBar").css("top", '66.4%');
            return false;
        });






        $('#show6').click(function (e) {
            e.preventDefault();
            $('#content1, #content2, #content3, #content4, #content5, #image1, #image2, #image3, #image4, #image5').hide();
            $('#show1, #show2, #show3, #show4, #show5').removeClass('active');
            $("#slideBar1, #slideBar2, #slideBar3, #slideBar4, #slideBar5").removeClass("active");
            $("#show6").addClass("active");
            $('#content6, #image6').show();
            $(".slideBar").css("top", '83%');
            return false;
        });
    });

    // $('#show1').click(function(e) {
    //     e.preventDefault();
    //     $('#content2, #content3, #content4, #content5, #image2, #image3, #image4, #image5').fadeOut();
    //     $('#show2, #show3, #show4, #show5').removeClass('active');
    //     $('#content1, #image1').fadeIn();
    //     this.removeClass('active');
    // });
    //
    // $('#show2').click(function(e) {
    //     e.preventDefault();
    //     $('#content1, #content3, #content4, #content5, #image1, #image3, #image4, #image5').fadeOut();
    //     $('#show1, #show3, #show4, #show5').removeClass('active');
    //     $('#content2, #image2').fadeIn();
    //     this.addClass('active');
    // });
    //
    // $('#show3').click(function(e) {
    //     e.preventDefault();
    //     $('#content1, #content2, #content4, #content5, #image1, #image2, #image4, #image5').fadeOut();
    //     $('#show1, #show2, #show4, #show5').removeClass('active');
    //     $('#content3, #image3').fadeIn();
    //     this.addClass('active');
    // });
    // $('#show4').click(function(e) {
    //     e.preventDefault();
    //     $('#content1, #content2, #content3, #content5, #image1, #image2, #image3, #image5').fadeOut();
    //     $('#show1, #show2, #show3, #show5').removeClass('active');
    //     $('#content4, #image4').fadeIn();
    //     this.addClass('active');
    // });
    // $('#show5').click(function(e) {
    //     e.preventDefault();
    //     $('#content1, #content2, #content3, #content4, #image1, #image2, #image3, #image4').fadeOut();
    //     $('#show1, #show2, #show3, #show4').removeClass('active');
    //     $('#content5, #image5').fadeIn();
    //     this.addClass('active');
    // });


});

(function () {
    var $$ = function (selector, context) {
        var context = context || document;
        var elements = context.querySelectorAll(selector);
        return [].slice.call(elements);
    };

    function _fncSliderInit($slider, options) {
        var prefix = ".fnc-";

        var $slider = $slider;
        var $slidesCont = $slider.querySelector(prefix + "slider__slides");
        var $slides = $$(prefix + "slide", $slider);
        var $controls = $$(prefix + "nav__control", $slider);
        var $controlsBgs = $$(prefix + "nav__bg", $slider);
        var $progressAS = $$(prefix + "nav__control-progress", $slider);

        var numOfSlides = $slides.length;
        var curSlide = 1;
        var sliding = false;
        var slidingAT = +parseFloat(getComputedStyle($slidesCont)["transition-duration"]) * 1000;
        var slidingDelay = +parseFloat(getComputedStyle($slidesCont)["transition-delay"]) * 1000;

        var autoSlidingActive = false;
        var autoSlidingTO;
        var autoSlidingDelay = 5000; // default autosliding delay value
        var autoSlidingBlocked = false;

        var $activeSlide;
        var $activeControlsBg;
        var $prevControl;

        function setIDs() {
            $slides.forEach(function ($slide, index) {
                $slide.classList.add("fnc-slide-" + (index + 1));
            });

            $controls.forEach(function ($control, index) {
                $control.setAttribute("data-slide", index + 1);
                $control.classList.add("fnc-nav__control-" + (index + 1));
            });

            $controlsBgs.forEach(function ($bg, index) {
                $bg.classList.add("fnc-nav__bg-" + (index + 1));
            });
        };

        setIDs();

        function afterSlidingHandler() {
            $slider.querySelector(".m--previous-slide").classList.remove("m--active-slide", "m--previous-slide");
            $slider.querySelector(".m--previous-nav-bg").classList.remove("m--active-nav-bg", "m--previous-nav-bg");

            $activeSlide.classList.remove("m--before-sliding");
            $activeControlsBg.classList.remove("m--nav-bg-before");
            $prevControl.classList.remove("m--prev-control");
            $prevControl.classList.add("m--reset-progress");
            var triggerLayout = $prevControl.offsetTop;
            $prevControl.classList.remove("m--reset-progress");

            sliding = false;
            var layoutTrigger = $slider.offsetTop;

            if (autoSlidingActive && !autoSlidingBlocked) {
                setAutoslidingTO();
            }
        };

        function performSliding(slideID) {
            if (sliding) return;
            sliding = true;
            window.clearTimeout(autoSlidingTO);
            curSlide = slideID;

            $prevControl = $slider.querySelector(".m--active-control");
            $prevControl.classList.remove("m--active-control");
            $prevControl.classList.add("m--prev-control");
            $slider.querySelector(prefix + "nav__control-" + slideID).classList.add("m--active-control");

            $activeSlide = $slider.querySelector(prefix + "slide-" + slideID);
            $activeControlsBg = $slider.querySelector(prefix + "nav__bg-" + slideID);

            $slider.querySelector(".m--active-slide").classList.add("m--previous-slide");
            $slider.querySelector(".m--active-nav-bg").classList.add("m--previous-nav-bg");

            $activeSlide.classList.add("m--before-sliding");
            $activeControlsBg.classList.add("m--nav-bg-before");

            var layoutTrigger = $activeSlide.offsetTop;

            $activeSlide.classList.add("m--active-slide");
            $activeControlsBg.classList.add("m--active-nav-bg");

            setTimeout(afterSlidingHandler, slidingAT + slidingDelay);
        };



        function controlClickHandler() {
            if (sliding) return;
            if (this.classList.contains("m--active-control")) return;
            if (options.blockASafterClick) {
                autoSlidingBlocked = true;
                $slider.classList.add("m--autosliding-blocked");
            }

            var slideID = +this.getAttribute("data-slide");

            performSliding(slideID);
        };

        $controls.forEach(function ($control) {
            $control.addEventListener("click", controlClickHandler);
        });

        function setAutoslidingTO() {
            window.clearTimeout(autoSlidingTO);
            var delay = +options.autoSlidingDelay || autoSlidingDelay;
            curSlide++;
            if (curSlide > numOfSlides) curSlide = 1;

            autoSlidingTO = setTimeout(function () {
                performSliding(curSlide);
            }, delay);
        };

        if (options.autoSliding || +options.autoSlidingDelay > 0) {
            if (options.autoSliding === false) return;

            autoSlidingActive = true;
            setAutoslidingTO();

            $slider.classList.add("m--with-autosliding");
            var triggerLayout = $slider.offsetTop;

            var delay = +options.autoSlidingDelay || autoSlidingDelay;
            delay += slidingDelay + slidingAT;

            $progressAS.forEach(function ($progress) {
                $progress.style.transition = "transform " + (delay / 1000) + "s";
            });
        }

        $slider.querySelector(".fnc-nav__control:first-child").classList.add("m--active-control");

    };

    var fncSlider = function (sliderSelector, options) {
        var $sliders = $$(sliderSelector);

        $sliders.forEach(function ($slider) {
            _fncSliderInit($slider, options);
        });
    };

    window.fncSlider = fncSlider;

}());


/* not part of the slider scripts */

/* Slider initialization
options:
autoSliding - boolean
autoSlidingDelay - delay in ms. If audoSliding is on and no value provided, default value is 5000
blockASafterClick - boolean. If user clicked any sliding control, autosliding won't start again
*/
fncSlider(".example-slider", { autoSlidingDelay: 4000 });

var $demoCont = document.querySelector(".demo-cont");

[].slice.call(document.querySelectorAll(".fnc-slide__action-btn")).forEach(function ($btn) {
    $btn.addEventListener("click", function () {
        $demoCont.classList.toggle("credits-active");
    });
});

document.querySelector(".demo-cont__credits-close").addEventListener("click", function () {
    $demoCont.classList.remove("credits-active");
});

document.querySelector(".js-activate-global-blending").addEventListener("click", function () {
    document.querySelector(".example-slider").classList.toggle("m--global-blending-active");
});
